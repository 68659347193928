import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_designereyes/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {OverviewWrapper} from '../pages/dashboard/OverviewWrapper'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RetailReadinessReportWrapper} from '../pages/dashboard/Apps/RetailReadinessReportWrapper'
import {ProductDataReportWrapper} from '../pages/dashboard/Apps/ProductDataReportWrapper'
import {InventoryInsightsWrapper} from '../pages/dashboard/InventoryInsights'
import {ConsumerMetricsWrapper} from '../pages/dashboard/ConsumerMetricsWrapper'
import {EcommerceWrapper} from '../pages/dashboard/EcommerceWrapper'
import {RetailPerformanceWrapper} from '../pages/dashboard/RetailPerformanceWrapper'
import {AmazonWrapper} from '../pages/dashboard/AmazonWrapper'
import {BrandSearchWrapper} from '../pages/dashboard/amazonResearch/AmazonBrandSearchWrapper'
import {SellerSearchWrapper} from '../pages/dashboard/amazonResearch/AmazonSellerSearchWrapper'
import {CostaSearchWrapper} from '../pages/dashboard/amazonResearch/costa/AmazonBrandCostaWrapper'
import {SellerCostaWrapper} from '../pages/dashboard/amazonResearch/costa/AmazonSellerCostaWrapper'
import {AIForecastingWrapper} from '../pages/dashboard/AIForecasting'
import {CatalogWrapper} from '../pages/uploadPages/CatalogWrapper'
import {ImageUploadWrapper} from '../pages/uploadPages/ImageUploadWrapper'
import {DocumentsWrapper} from '../pages/documentsPage/DocumentsWrapper'
import {ChangelogWrapper} from '../pages/changelog/changeLog'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_designereyes/assets/ts/_utils'
import {WithChildren} from '../../_designereyes/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {IsraelWrapper} from '../pages/dashboard/distribution/IsraelWrapper'
import {CaribbeanWrapper} from '../pages/dashboard/distribution/CaribbeanWrapper'
import {DistributorAccountProvider} from '../../_designereyes/partials/widgets'

import {CustomerMasterListWrapper} from '../pages/dashboard/purchaseOrders/CustomerMasterListWrapper'
import {POControlCenterWrapper} from '../pages/dashboard/purchaseOrders/POControlCenterWrapper'
import {MasterPOWrapper} from '../pages/dashboard/purchaseOrders/MasterPOWrapper'
import {CustomerPOWrapper} from '../pages/dashboard/purchaseOrders/CustomerPOWrapper'
import {CustomerOrdersWrapper} from '../pages/dashboard/purchaseOrders/CustomerOrdersWrapper'

import {CustomerViewWrapper} from '../pages/dashboard/purchaseOrders/customerView/CustomerViewWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <DistributorAccountProvider>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='overview' element={<OverviewWrapper />} />
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='/apps/product-data-report' element={<ProductDataReportWrapper />} />
          <Route path='/apps/retail-readiness-report' element={<RetailReadinessReportWrapper />} />
          <Route path='purchase-orders/list' element={<CustomerMasterListWrapper />} />
          <Route path='purchase-orders/main' element={<POControlCenterWrapper />} />
          <Route path='purchase-orders/master' element={<MasterPOWrapper />} />
          <Route path='purchase-orders/customer' element={<CustomerPOWrapper />} />
          <Route path='purchase-orders/orders' element={<CustomerOrdersWrapper />} />
          <Route path='purchase-orders/customer-view' element={<CustomerViewWrapper />} />

          {/*  */}
          <Route path='inventory-insights' element={<InventoryInsightsWrapper />} />
          <Route path='retail-performance' element={<RetailPerformanceWrapper />} />

          <Route path='ai-forecasting' element={<AIForecastingWrapper />} />
          <Route path='catalog' element={<CatalogWrapper />} />
          <Route path='image-upload' element={<ImageUploadWrapper />} />
          <Route path='distribution/israel' element={<IsraelWrapper />} />
          <Route path='distribution/caribbean' element={<CaribbeanWrapper />} />
          <Route path='documents123123' element={<DocumentsWrapper />} />
          <Route path='consumer-metrics' element={<ConsumerMetricsWrapper />} />
          <Route path='ecommerce' element={<EcommerceWrapper />} />

          {/* Pages */}
          <Route path='amazon' element={<AmazonWrapper />} />
          <Route path='brands' element={<BrandSearchWrapper />} />
          <Route path='sellers' element={<SellerSearchWrapper />} />
          <Route path='brands/costa' element={<CostaSearchWrapper />} />
          <Route path='sellers/costa' element={<SellerCostaWrapper />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route
            path='/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Lazy Modules */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />

          <Route path='changelog' element={<ChangelogWrapper />} />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </DistributorAccountProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
