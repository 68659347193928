/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, FC} from 'react'
import {toAbsoluteUrl} from '../../../../_designereyes/helpers'
import {PageTitle} from '../../../../_designereyes/layout/core'
import {useAuth} from '../../../modules/auth'
import axios from 'axios'

import {API_URL} from '../../../modules/auth/core/_requests'

const ProductDataReportPage: FC = () => {
  const [searchCriterion, setSearchCriterion] = useState<string>('UPC')
  const [productValues, setProductValues] = useState<string>('')

  interface FieldOption {
    label: string
    value: string
    checked: boolean
  }

  interface FieldOption {
    label: string
    value: string
    checked: boolean
  }

  // Master Fields
  const [masterFields, setMasterFields] = useState<FieldOption[]>([
    {label: 'UPC', value: 'upc', checked: false},
    {label: 'NAV SKU', value: 'sku_nav', checked: false},
    {label: 'EC SKU', value: 'sku', checked: false},
    {label: 'ASIN', value: 'asin', checked: false},
    {label: 'Model', value: 'model', checked: false},
    {label: 'Size Code', value: 'size_code', checked: false},
    {label: 'Color Code', value: 'color_code', checked: false},
    {label: 'Category', value: 'category', checked: false},
    {label: 'Product Type', value: 'product_type', checked: false},
    {label: 'Collection', value: 'collection', checked: false},
    {label: 'Vendor', value: 'vendor_name', checked: false},
    {label: 'Brand', value: 'brand', checked: false},
    {label: 'Brand Code', value: 'brand_code', checked: false},
    {label: 'Color', value: 'color', checked: false},
    {label: 'Gender', value: 'gender', checked: false},
    {label: 'Size', value: 'size', checked: false},
    {label: 'Name', value: 'name', checked: false},
    {label: 'Description', value: 'long_description', checked: false},
  ])

  // Category Specific Fields
  const [categorySpecificFields, setCategorySpecificFields] = useState<FieldOption[]>([
    {label: 'Lens Width', value: 'lens_width', checked: false},
    {label: 'Bridge Width', value: 'bridge_width', checked: false},
    {label: 'Temple Length', value: 'temple_length', checked: false},
    {label: 'Frame Color', value: 'frame_color', checked: false},
    {label: 'Frame Material', value: 'frame_material', checked: false},
    {label: 'Lens Color', value: 'lens_color', checked: false},
    {label: 'Lens Color Code', value: 'lens_color_code', checked: false},
    {label: 'Lens Material', value: 'lens_material', checked: false},
    {label: 'Lens Shape', value: 'lens_shape', checked: false},
    {label: 'Polarized', value: 'polarized', checked: false},
    {label: 'Rxable', value: 'rxable', checked: false},
  ])

  // Financial Fields
  const [financialFields, setFinancialFields] = useState<FieldOption[]>([
    {label: 'FIFO Avg Cost', value: 'fifo_avg_cost', checked: false},
    {label: 'FIFO Cost', value: 'fifo_cost', checked: false},
    {label: 'Direct Cost', value: 'direct_cost', checked: false},
    {label: 'Inventory', value: 'inventory', checked: false},
    {label: 'Map Price', value: 'map_price', checked: false},
    {label: 'Min Amazon Price', value: 'final_min_price', checked: false},
  ])

  const handleFieldChange = (
    fieldType: 'master' | 'categorySpecific' | 'financial',
    index: number
  ) => {
    const updateFields = (
      fields: FieldOption[],
      setFields: React.Dispatch<React.SetStateAction<FieldOption[]>>
    ) => {
      const newFields = [...fields]
      newFields[index].checked = !newFields[index].checked
      setFields(newFields)
    }

    if (fieldType === 'master') {
      updateFields(masterFields, setMasterFields)
    } else if (fieldType === 'categorySpecific') {
      updateFields(categorySpecificFields, setCategorySpecificFields)
    } else if (fieldType === 'financial') {
      updateFields(financialFields, setFinancialFields)
    }
  }

  const handleSelectAll = (
    fieldType: 'master' | 'categorySpecific' | 'financial',
    selectAll: boolean
  ) => {
    const updateFields = (
      fields: FieldOption[],
      setFields: React.Dispatch<React.SetStateAction<FieldOption[]>>
    ) => {
      const newFields = fields.map((field) => ({...field, checked: selectAll}))
      setFields(newFields)
    }

    if (fieldType === 'master') {
      updateFields(masterFields, setMasterFields)
    } else if (fieldType === 'categorySpecific') {
      updateFields(categorySpecificFields, setCategorySpecificFields)
    } else if (fieldType === 'financial') {
      updateFields(financialFields, setFinancialFields)
    }
  }

  const handleDownload = async () => {
    // Gather selected fields
    const selectedFields = {
      master: masterFields.filter((field) => field.checked).map((field) => field.value),
      categorySpecific: categorySpecificFields
        .filter((field) => field.checked)
        .map((field) => field.value),
      financial: financialFields.filter((field) => field.checked).map((field) => field.value),
    }

    // Get the product values from the textarea
    const products = productValues
      .split('\n')
      .map((value) => value.trim())
      .filter(Boolean)

    // Prepare the payload
    const payload = {
      searchCriterion,
      products,
      selectedFields,
    }

    try {
      // Send a POST request to the backend
      const response = await axios.post(`${API_URL}/api/product-data`, payload, {
        responseType: 'blob', // Important for handling binary data
      })

      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'product_data.xlsx') // or any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error downloading the file:', error)
      // Handle error appropriately
    }
  }

  return (
    <div className='card m-1 text-light'>
      <div className='card-header'>
        <h2 className='card-title mb-0'>Select Your Fields</h2>
      </div>
      <div className='card-body'>
        {/* Radio Buttons for Search Criteria */}
        <div className='my-3'>
          <label className='form-label text-white'>Select Search Criterion:</label>
          <div>
            <div className='form-check form-check-inline m-2'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionUPC'
                value='UPC'
                checked={searchCriterion === 'UPC'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionUPC'>
                UPC
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionNAVSKU'
                value='NAV SKU'
                checked={searchCriterion === 'NAV SKU'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionNAVSKU'>
                NAV SKU
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='searchCriterion'
                id='searchCriterionECSKU'
                value='EC SKU'
                checked={searchCriterion === 'EC SKU'}
                onChange={(e) => setSearchCriterion(e.target.value)}
              />
              <label className='form-check-label text-white' htmlFor='searchCriterionECSKU'>
                EC SKU
              </label>
            </div>
          </div>
        </div>
        {/* Input Section */}
        <div className='my-4'>
          <label htmlFor='productValues' className='form-label text-white'>
            Enter Product Values (one per line):
          </label>
          <textarea
            className='form-control'
            id='productValues'
            rows={5}
            value={productValues}
            onChange={(e) => setProductValues(e.target.value)}
          ></textarea>
        </div>
        {/* Input Section End */}

        {/* Field Selection Sections */}
        <div className='my-4'>
          <label className='form-label text-white'>Select Fields to Include:</label>

          {/* Accordion Component */}
          <div className='accordion' id='fieldSelectionAccordion'>
            {/* Master Fields Accordion Item */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingMaster'>
                <button
                  className='accordion-button bg-light'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseMaster'
                  aria-expanded='true'
                  aria-controls='collapseMaster'
                >
                  Master Fields
                </button>
              </h2>
              <div
                id='collapseMaster'
                className='accordion-collapse collapse show'
                aria-labelledby='headingMaster'
                data-bs-parent='#fieldSelectionAccordion'
              >
                <div className='accordion-body'>
                  {/* Select All/Deselect All Buttons */}
                  <div className='my-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2'
                      onClick={() => handleSelectAll('master', true)}
                    >
                      Select All
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary'
                      onClick={() => handleSelectAll('master', false)}
                    >
                      Deselect All
                    </button>
                  </div>
                  {/* Checkbox Grid */}
                  <div className='row'>
                    {masterFields.map((field, index) => (
                      <div className='col-md-3' key={field.value}>
                        <div className='form-check my-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`masterField-${field.value}`}
                            checked={field.checked}
                            onChange={() => handleFieldChange('master', index)}
                          />
                          <label
                            className='form-check-label text-white'
                            htmlFor={`masterField-${field.value}`}
                          >
                            {field.label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Category Specific Fields Accordion Item */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingCategory'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseCategory'
                  aria-expanded='false'
                  aria-controls='collapseCategory'
                >
                  Category Specific Fields
                </button>
              </h2>
              <div
                id='collapseCategory'
                className='accordion-collapse collapse'
                aria-labelledby='headingCategory'
                data-bs-parent='#fieldSelectionAccordion'
              >
                <div className='accordion-body'>
                  {/* Select All/Deselect All Buttons */}
                  <div className='my-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2'
                      onClick={() => handleSelectAll('categorySpecific', true)}
                    >
                      Select All
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary'
                      onClick={() => handleSelectAll('categorySpecific', false)}
                    >
                      Deselect All
                    </button>
                  </div>
                  {/* Checkbox Grid */}
                  <div className='row'>
                    {categorySpecificFields.map((field, index) => (
                      <div className='col-md-3' key={field.value}>
                        <div className='form-check my-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`categoryField-${field.value}`}
                            checked={field.checked}
                            onChange={() => handleFieldChange('categorySpecific', index)}
                          />
                          <label
                            className='form-check-label text-white'
                            htmlFor={`categoryField-${field.value}`}
                          >
                            {field.label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Financial Fields Accordion Item */}
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFinancial'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFinancial'
                  aria-expanded='false'
                  aria-controls='collapseFinancial'
                >
                  Financial Fields
                </button>
              </h2>
              <div
                id='collapseFinancial'
                className='accordion-collapse collapse'
                aria-labelledby='headingFinancial'
                data-bs-parent='#fieldSelectionAccordion'
              >
                <div className='accordion-body'>
                  {/* Select All/Deselect All Buttons */}
                  <div className='my-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary me-2'
                      onClick={() => handleSelectAll('financial', true)}
                    >
                      Select All
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-secondary'
                      onClick={() => handleSelectAll('financial', false)}
                    >
                      Deselect All
                    </button>
                  </div>
                  {/* Checkbox Grid */}
                  <div className='row'>
                    {financialFields.map((field, index) => (
                      <div className='col-md-3' key={field.value}>
                        <div className='form-check my-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`financialField-${field.value}`}
                            checked={field.checked}
                            onChange={() => handleFieldChange('financial', index)}
                          />
                          <label
                            className='form-check-label text-white'
                            htmlFor={`financialField-${field.value}`}
                          >
                            {field.label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='text-center my-5'>
          <button type='button' className='btn btn-success my-3' onClick={handleDownload}>
            Download to Excel
          </button>
        </div>
      </div>
    </div>
  )
}

export const ProductDataReportWrapper: FC = () => {
  return (
    <>
      <PageTitle>Product Data Puller</PageTitle>
      <ProductDataReportPage />
    </>
  )
}
